/*

  Theme: Griddo theme

  -----------------------------------
  🚫 DO NOT EDIT THIS FILE DIRECTLY 🚫
  -----------------------------------

  To generate this file:
  1 - Edit `src/themes/griddo-theme` files
  2 - Run `yarn build:themes`

*/

[data-theme="griddo-theme"] {
	/* fontFamily */
	--font-1: Gilroy, Arial, Helvetica, sans-serif;
	/* brandColors */
	--brand-1: #3cc3dd;
	--brand-2: #ffffff;
	--brand-3: #19e99e;
}
[data-theme="griddo-theme"][data-subtheme="inverse"],
[data-theme="griddo-theme"] [data-subtheme="inverse"] {
	/* overlays */
	--overlay-1: #ffffff80;
	--overlay-2: #ffffff33;
	--overlay-3: #ffffffe5;
	--overlay-4: #3cc3dd4d;
	--overlay-5: #00132a80;
	/* support */
	--success: #37f4b0;
	--error: #fa0067;
	--focus: #85e1f2;
	--focus-line: #516dfb;
	/* shadowColor */
	--shadow-1: #0009134d;
	/* boxShadow */
	--box-shadow-sm: 0px 4px 18px rgba(var(--shadow-1), 0.08);
	--box-shadow-md: 0px 5px 26px rgba(var(--shadow-1), 0.12);
	--box-shadow-lg: 0px 20px 34px rgba(var(--shadow-1), 0.1);
	--box-shadow-xl: 0px 18px 26px rgba(var(--shadow-1), 0.2);
	/* menuInteraction */
	--menu-interaction-1: transparent;
	--menu-interaction-1-hover: #19e99e;
	--menu-interaction-1-active: #17d493;
	--menu-interaction-1-focus: transparent;
	/* inverse */
	--inverse-1: #00132a;
	--inverse-2: #ffffff;
	/* graphics */
	--graphics-1: #00132a26;
	--graphics-2: #3cc3dd;
	--graphics-3: #19e99e;
	--graphics-4: #b1ebf6;
	--graphics-5: #abdcca;
	--graphics-6: #fbe15c;
	--graphics-7: #a950cf;
	--graphics-8: #a950cf4d;
	/* text */
	--text-1: #001b3c;
	--text-2: #001b3c;
	--text-3: #001b3c;
	--text-4: #3cc3dd;
	--text-disabled: #999999;
	/* icon */
	--icon-1: #001b3c;
	--icon-2: #3cc3dd;
	--icon-3: #001b3c;
	--icon-disabled: #999999;
	/* backgroundButton */
	--bg-button-1: #19e99e;
	--bg-button-1-hover: #14be86;
	--bg-button-1-active: #14be86;
	--bg-button-1-focus: #19e99e;
	--bg-button-1-disabled: #344457;
	--bg-button-2: #3cc3dd;
	--bg-button-2-hover: #30a0b9;
	--bg-button-2-active: #30a0b9;
	--bg-button-2-focus: #3cc3dd;
	--bg-button-2-disabled: #344457;
	--bg-button-3: #ffffff00;
	--bg-button-3-hover: #ffffff00;
	--bg-button-3-active: #ffffff00;
	--bg-button-3-focus: #ffffff00;
	--bg-button-3-disabled: #ffffff00;
	--bg-button-4: #19e99e;
	--bg-button-4-hover: #14be86;
	--bg-button-4-active: #14be86;
	--bg-button-4-focus: #19e99e;
	--bg-button-4-disabled: #344457;
	/* borderButton */
	--border-button-1: transparent;
	--border-button-1-hover: transparent;
	--border-button-1-active: transparent;
	--border-button-1-focus: transparent;
	--border-button-1-disabled: transparent;
	--border-button-2: transparent;
	--border-button-2-hover: transparent;
	--border-button-2-active: transparent;
	--border-button-2-focus: transparent;
	--border-button-2-disabled: transparent;
	--border-button-3: transparent;
	--border-button-3-hover: transparent;
	--border-button-3-active: transparent;
	--border-button-3-focus: transparent;
	--border-button-3-disabled: transparent;
	--border-button-4: transparent;
	--border-button-4-hover: transparent;
	--border-button-4-active: transparent;
	--border-button-4-focus: transparent;
	--border-button-4-disabled: transparent;
	/* textButton */
	--label-button-1: #00132a;
	--label-button-1-hover: #00132a;
	--label-button-1-active: #00132a;
	--label-button-1-focus: #00132a;
	--label-button-1-disabled: #6c7c8e;
	--label-button-2: #00132a;
	--label-button-2-hover: #00132a;
	--label-button-2-active: #00132a;
	--label-button-2-focus: #00132a;
	--label-button-2-disabled: #6c7c8e;
	--label-button-3: #3cc3dd;
	--label-button-3-hover: #30a0b9;
	--label-button-3-active: #30a0b9;
	--label-button-3-focus: #3cc3dd;
	--label-button-3-disabled: #6c7c8e;
	--label-button-4: #00132a;
	--label-button-4-hover: #00132a;
	--label-button-4-active: #00132a;
	--label-button-4-focus: #00132a;
	--label-button-4-disabled: #6c7c8e;
	/* textLink */
	--text-link-1: #3cc3dd;
	--text-link-1-hover: #3cc3dd;
	--text-link-1-active: #3cc3dd;
	--text-link-1-focus: #3cc3dd;
	--text-link-1-disabled: #344457;
	--text-link-2: #3cc3dd;
	--text-link-2-hover: #3cc3dd;
	--text-link-2-active: #3cc3dd;
	--text-link-2-focus: #3cc3dd;
	--text-link-2-disabled: #344457;
	--menu-link-1: #001b3c;
	--menu-link-1-hover: #001b3c;
	--menu-link-1-active: #001b3c;
	--menu-link-1-focus: #001b3c;
	--menu-link-1-disabled: #999999;
	--menu-link-2: #001b3c;
	--menu-link-2-hover: #19e99e;
	--menu-link-2-active: #19e99e;
	--menu-link-2-focus: #001b3c;
	--menu-link-2-disabled: #999999;
	/* border */
	--border-1: #eeeeeebf;
	--border-2: #a4a9b7;
	--border-3: #3cc3dd;
	--border-disabled: #e6e6e6;
	/* bg */
	--bg-ui: #ffffff;
	--bg-1: #ffffff;
	--bg-2: #dbf4f9;
	--bg-3: #3cc3dd;
	--bg-4: #ebfbfe;
	--bg-5: #ebfbfe;
	--bg-disabled: #e6e6e6;
}
[data-theme="griddo-theme"][data-subtheme="default"],
[data-theme="griddo-theme"] [data-subtheme="default"] {
	/* overlays */
	--overlay-1: #010d1b99;
	--overlay-2: #010d1b7a;
	--overlay-3: #00132ab2;
	--overlay-4: #3cc3dd4d;
	--overlay-5: #00132a80;
	/* support */
	--success: #37f4b0;
	--error: #fa0067;
	--focus: #ffffff;
	--focus-line: #516dfb;
	/* shadowColor */
	--shadow-1: #000913;
	/* boxShadow */
	--box-shadow-sm: 0px 4px 18px rgba(var(--shadow-1), 0.08);
	--box-shadow-md: 0px 5px 26px rgba(var(--shadow-1), 0.12);
	--box-shadow-lg: 0px 20px 34px rgba(var(--shadow-1), 0.1);
	--box-shadow-xl: 0px 18px 26px rgba(var(--shadow-1), 0.2);
	/* menuInteraction */
	--menu-interaction-1: transparent;
	--menu-interaction-1-hover: #19e99e;
	--menu-interaction-1-active: #17d493;
	--menu-interaction-1-focus: transparent;
	/* inverse */
	--inverse-1: #ffffff;
	--inverse-2: #00132a;
	/* graphics */
	--graphics-1: #00132a;
	--graphics-2: #3cc3dd;
	--graphics-3: #19e99e;
	--graphics-4: #b1ebf6;
	--graphics-5: #abdcca;
	--graphics-6: #fbe15c;
	--graphics-7: #a950cf;
	--graphics-8: #a950cf4d;
	/* text */
	--text-1: #eeeeee;
	--text-2: #eeeeee;
	--text-3: #eeeeee;
	--text-4: #3cc3dd;
	--text-disabled: #46505c;
	/* icon */
	--icon-1: #eeeeee;
	--icon-2: #3cc3dd;
	--icon-3: #eeeeee;
	--icon-disabled: #46505c;
	/* backgroundButton */
	--bg-button-1: #19e99e;
	--bg-button-1-hover: #14be86;
	--bg-button-1-active: #14be86;
	--bg-button-1-focus: #19e99e;
	--bg-button-1-disabled: #344457;
	--bg-button-2: #3cc3dd;
	--bg-button-2-hover: #30a0b9;
	--bg-button-2-active: #30a0b9;
	--bg-button-2-focus: #3cc3dd;
	--bg-button-2-disabled: #344457;
	--bg-button-3: transparent;
	--bg-button-3-hover: transparent;
	--bg-button-3-active: transparent;
	--bg-button-3-focus: transparent;
	--bg-button-3-disabled: transparent;
	--bg-button-4: #19e99e;
	--bg-button-4-hover: #14be86;
	--bg-button-4-active: #14be86;
	--bg-button-4-focus: #19e99e;
	--bg-button-4-disabled: #344457;
	/* borderButton */
	--border-button-1: transparent;
	--border-button-1-hover: transparent;
	--border-button-1-active: transparent;
	--border-button-1-focus: transparent;
	--border-button-1-disabled: transparent;
	--border-button-2: transparent;
	--border-button-2-hover: transparent;
	--border-button-2-active: transparent;
	--border-button-2-focus: transparent;
	--border-button-2-disabled: transparent;
	--border-button-3: transparent;
	--border-button-3-hover: transparent;
	--border-button-3-active: transparent;
	--border-button-3-focus: transparent;
	--border-button-3-disabled: transparent;
	--border-button-4: transparent;
	--border-button-4-hover: transparent;
	--border-button-4-active: transparent;
	--border-button-4-focus: transparent;
	--border-button-4-disabled: transparent;
	/* textButton */
	--label-button-1: #00132a;
	--label-button-1-hover: #00132a;
	--label-button-1-active: #00132a;
	--label-button-1-focus: #00132a;
	--label-button-1-disabled: #6c7c8e;
	--label-button-2: #00132a;
	--label-button-2-hover: #00132a;
	--label-button-2-active: #00132a;
	--label-button-2-focus: #00132a;
	--label-button-2-disabled: #6c7c8e;
	--label-button-3: #3cc3dd;
	--label-button-3-hover: #30a0b9;
	--label-button-3-active: #30a0b9;
	--label-button-3-focus: #3cc3dd;
	--label-button-3-disabled: #6c7c8e;
	--label-button-4: #00132a;
	--label-button-4-hover: #00132a;
	--label-button-4-active: #00132a;
	--label-button-4-focus: #00132a;
	--label-button-4-disabled: #6c7c8e;
	/* textLink */
	--text-link-1: #3cc3dd;
	--text-link-1-hover: #3cc3dd;
	--text-link-1-active: #3cc3dd;
	--text-link-1-focus: #3cc3dd;
	--text-link-1-disabled: #344457;
	--text-link-2: #3cc3dd;
	--text-link-2-hover: #3cc3dd;
	--text-link-2-active: #3cc3dd;
	--text-link-2-focus: #3cc3dd;
	--text-link-2-disabled: #344457;
	--menu-link-1: #ffffff;
	--menu-link-1-hover: #ffffff;
	--menu-link-1-active: #ffffff;
	--menu-link-1-focus: #ffffff;
	--menu-link-1-disabled: #dbd8d8;
	--menu-link-2: #ffffff;
	--menu-link-2-hover: #19e99e;
	--menu-link-2-active: #19e99e;
	--menu-link-2-focus: #ffffff;
	--menu-link-2-disabled: #dbd8d8;
	/* border */
	--border-1: #eeeeeebf;
	--border-2: #eeeeee;
	--border-3: #3cc3dd;
	--border-disabled: #233347;
	/* bg */
	--bg-ui: #001b3c;
	--bg-1: #001b3c;
	--bg-2: #0f2b4e;
	--bg-3: #3cc3dd;
	--bg-4: #001b3c;
	--bg-5: #3d5777;
	--bg-disabled: #233347;
}
